<template>
  <SectionBasic
    id="support"
    :isActive="isActive">
    <h3 class="tit_support_section">투자 및 제휴 문의</h3>
    <div class="card_support">
      <dl>
        <dt>Email</dt>
        <dd>
          <a href="mailto:hello@lemontree.ai">hello@lemontree.ai</a>
        </dd>
      </dl>
      <dl>
        <dt>대표전화</dt>
        <dd>
          <a href="tel:031-726-9990">031-726-9990</a>
        </dd>
      </dl>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionSupport',
  props:{
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_support{padding:140px 0;background-color:#fff;text-align:center}
.section_support .tit_support_section{font-weight:700;font-size:32px;color:#111}
.card_support{width:714px;height:364px;margin:32px auto 0;padding:85px 332px 85px 56px;border-radius:8px;background:#8F69DD url(/assets/images/company/img_support.png) no-repeat top 32px right 32px;background-size:300px 300px;box-sizing:border-box;text-align:left}
.card_support dl + dl{margin-top:40px}
.card_support dl dt{font-weight:800;font-size:16px;line-height:24px;color:#D8CBF4}
.card_support dl dd{margin-top:19px}
.card_support dl dd a{display:block;font-family:'Poppins';font-weight:700;font-size:28px;line-height:34px;color:#fff}

/* 모바일 */
@media all and (max-width:1199px){
  .section_support{padding:60px 0}
  .section_support .tit_support_section{font-size:28px;line-height:42px}
  .card_support{width:100%;height:auto;padding:48px 24px 368px;background:#8F69DD url(/assets/images/company/img_support.png) no-repeat bottom 48px center;background-size:272px 272px}
  .card_support dl dd a{font-size:24px}
}
</style>