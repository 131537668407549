<template>
  <SectionBasic
    id="about"
    :isActive="isActive">
    <h3 class="txt_section">About us</h3>
    <p class="tit_section">용돈 교육을 시작으로,<br>레몬트리가 앞장서겠습니다.</p>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionAbout',
  props:{
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_about{padding:140px 0;background-color:#fff;text-align:center}
.section_about .tit_section{line-height:68px}

/* 모바일 */
@media all and (max-width:1199px){
  .section_about{padding:60px 0;text-align:left}
  .section_about .tit_section{line-height:42px}
}
</style>