export default class CompanyModel {
  constructor(){
    this.teamDataList = [
      {
        profileImg:'profile_kate',
        snsList:[
          { snsName: 'facebook', snsUrl:'https://www.facebook.com/ideaminhee' },
          { snsName: 'instagram', snsUrl:'https://www.instagram.com/minhee.kate/' },
        ],
        name: '이민희',
        position: 'CEO',
        career:'바풀 창업, 라인 Exit<br>네이버 Clova, 라인 신규사업&서비스 기획',
        message: '“제 아이들이 돈에 밝은 사람이 되길<br>바라는 부모의 마음으로 만듭니다.”'
      },
      {
        profileImg:'profile_harry',
        snsList:[
          { snsName: 'facebook', snsUrl:'https://www.facebook.com/friedpotato' },
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/jeongseok-kang-8a676275/' },
        ],
        name: '강정석',
        position: 'COO',
        career:'SK컴즈 검색기획, 쏘카 PMO,<br>42Maru AI기획<br>국가공인 자산관리사(FP)',
        message: `"다음 세대가 자신의 경제적 삶을 주체적으로 설계할 수 있도록, 레몬트리가 든든한 서포터가 되어주겠습니다."`
      },
      {
        profileImg:'profile_hari',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/hayoung-hari' },
        ],
        name: '박하영',
        position: 'CPO',
        career:'바풀 서비스 기획<br>네이버 Clova 서비스 기획',
        message: '"우리 아이들이 청년이 되는 날까지, <br>금융 출발선을 맞춰줄<br>서비스를 만들겠습니다."'
      },
      {
        profileImg:'profile_clark',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/daemyung-kang-7538a731/' },
        ],
        name: '강대명',
        position: 'CTO',
        career:'네이버 & 카카오 백엔드<br>위버스컴퍼니 데이터',
        message: '"아빠 엄마가 금융맹이라... <br>율이랑 솔이는 금융전문가로 만들고 싶어요."'
      },
      {
        profileImg:'profile_johnny',
        snsList:[
          { snsName: 'facebook', snsUrl:'https://www.facebook.com/popeye92' },
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/popeye92' },
        ],
        name: '조민재',
        position: 'CISO',
        career:'아톤 CISO<br>네이버, 쿠팡, 우아한형제들 보안',
        message: `"레몬트리의 금융 교육은<br>믿을만하고 안전합니다."`
      },
      {
        profileImg:'profile_sunny',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/sunjung-lee-53382312b/' },
        ],
        name: '이선정',
        position: 'Marketing Leader',
        career:'째깍악어, 이스트소프트 마케팅<br>MILVUS, 애경산업 CRM 캠페인 기획',
        message: `"더 많은 가정이 레몬트리로<br>용돈 교육을 시작할 수 있도록,<br>널리 이롭게 알리겠습니다."`
      },
      {
        profileImg:'profile_arr',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/danbi-wi-95604517a/' },
        ],
        name: '위단비',
        position: 'Growth Marketer',
        career:'트렌비, 어니스트플라워<br>패스트파이브, 패스트캠퍼스<br>Growth & Performance',
        message: `"가족이 함께 '돈 이야기'를 하는 것이<br>당연한 사회를 만드는 데 기여하는<br>서비스를 만들고 알립니다."`
      },
      {
        profileImg:'profile_steve',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/sang-kug-ye-0718b38a/' },
        ],
        name: '예상국',
        position: 'Server Team Leader',
        career:'카카오 & 카카오페이 백엔드<br>뱅크샐러드 백엔드',
        message: `"경험하라!<br>지금까지는 없었던 서비스를!"`
      },
      {
        profileImg:'profile_wayne',
        snsList:[
          { snsName: 'facebook', snsUrl:'https://www.facebook.com/wanbok' },
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/wanbok/' },
        ],
        name: '최완복',
        position: 'Client Team Leader',
        career:'하이퍼커넥트 iOS<br>라인뱅크 iOS',
        message: `"금융 교육 혁신에<br>한 발 올려 놓고 싶습니다."`
      },
      {
        profileImg:'profile_theo',
        snsList:[
          { snsName: 'facebook', snsUrl:'https://www.facebook.com/taehwan.dev' },
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/taehwankwon/' },
        ],
        name: '권태환',
        position: 'Android Engineer',
        career:'딜리버리히어로코리아 Android<br>카카오페이 Android',
        message: `"나에게 필요한!<br>모두에게 필요한 금융 서비스!<br>어릴때부터!"`
      },
      {
        profileImg:'profile_kerry',
        snsList:[
          { snsName: 'instagram', snsUrl:'https://www.instagram.com/___y___k___y___/' },
        ],
        name: '유보현',
        position: 'Frontend Engineer',
        career:'디케이테크인 FE',
        message: `"실천하는 가족 금융의<br>시작을 만들어갑니다."`
      },
      {
        profileImg:'profile_eden',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/yoobin-lee-638b00220/' },
        ],
        name: '이유빈',
        position: 'Backend Engineer',
        career:'다나와 백엔드',
        message: `"금융 교욱의 첫 단추는<br>레몬트리에서!"`
      },
      {
        profileImg:'profile_sophie',
        snsList:[
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/%EC%88%98%EA%B2%BD-%EB%B0%95-655239231/'}
        ],
        name: '박수경',
        position: 'Content Manager',
        career:'해시그랩 Content Manager<br>플래닛12 Content Manager',
        message: `"금융 교육에 '너무 이른 때'는 없다. <br>아이들이 쉽고 재밌게<br>금융을 알아갈 수 있도록!"`
      },
      {
        profileImg:'profile_vella',
        snsList:[
          { snsName: 'instagram', snsUrl:'https://www.instagram.com/uowa_/' },
        ],
        name: '송아름',
        position: 'UX/UI Designer',
        career:'런어데이 Product Designer<br>비건 화장품, 나의 온도 Brand Designer',
        message: `"아이와 부모님이 지속적으로 쓰고 싶은 <br>금융 교육앱을 만들겠습니다."`
      },
      {
        profileImg:'profile_default',
        snsList:[
          { snsName: 'instagram', snsUrl:'https://www.instagram.com/randyjlee/' },
          { snsName: 'linkedin', snsUrl:'https://www.linkedin.com/in/randyjleehj/' },
        ],
        name: '이호진',
        position: 'CDO',
        career:'네이버 & 카카오 서비스<br>Belong Founding Designer',
        message: `"우리 세대에서는 경험하지 못한<br>새로운 금융 서비스를 다음 세대를 위해 만들어 가겠습니다."`
      },
    ],
    this.pressDataList = [
      { title:`레몬트리, 자녀용돈관리 위한 10대전용카드 '퍼핀' 출시`, content:`에듀핀테크 기업 레몬트리(대표 이민희)가 자녀용돈관리서비스 '퍼핀'을 출시했다고 29일 밝혔다. 레몬트리는 부모가 안전하게 통제 가능한 자녀용돈관리 및 경제금융교육 서비스 '퍼핀앱'을 구글플레이스토어를 통해 안드로이드 사용자에게 먼저 제공한다. 퍼핀은 7세 이상 자녀 본인 명의 휴대폰으로 가입 가능하며, 퍼핀카드는 별도의 은행 방문 및 자녀 계좌 개설 없이 부모와 자녀가 간편한 인증을 거쳐 만들 수 있다.`, quote:"ZDNetKorea", date:"2023.03.29", url:"https://zdnet.co.kr/view/?no=20230329100255" },
      { title:`선불형 용돈카드 ‘퍼핀’ 속 레일플러스 기능 ‘쏙’`, content:`한국철도공사(코레일)는 자녀용돈 선불카드 ‘퍼핀(firfin)’카드에 전국 호환 교통카드 레일플러스의 기능을 제공한다고 29일 밝혔다. ‘퍼핀’은 핀테크 스타트업 레몬트리가 개발한 ‘어린이?청소년을 위해 부모가 선불로 용돈을 충전하는 금융 플랫폼’으로 자녀는 앱으로 용돈을 받고 소비내역을 가족과 공유할 수 있다.`, quote:"서울경제", date:"2023.03.29", url:"https://www.sedaily.com/NewsView/29N7QZPCYI" },
      { title:`자녀 용돈관리·경제교육, 앱 하나로 다 되네`, content:`에듀핀테크 기업 레몬트리가 자녀용돈관리 및 경제금융교육서비스 ‘퍼핀(first fintech for family, firfin)’을 출시했다고 28일 밝혔다. 전국호환 교통카드(레일플러스) 겸용 용돈카드인 ‘퍼핀카드’와 체계적인 경제금융학습용 메타버스 콘텐츠 ‘퍼핀월드’를 앱(APP)에 탑재했다.`, quote:"매일경제", date:"2023.03.28", url:"https://www.mk.co.kr/news/economy/10698083" },
      { title:`에듀핀테크 '레몬트리' 전자금융업 등록`, content:`에듀핀테크 기업 레몬트리가 금융위원회와 금융감독원으로부터 전자금융업 라이선스를 받고 서비스 출시에 속도를 낸다. 이 회사는 초등학생 자녀 용돈 관리와 경제 교육을 동시에 할 수 있는 '퍼핀(firfin·first fintech for family)' 서비스를 개발 중이다. 부모가 자녀의 경제생활을 유연하게 통제하면서 '돈 공부'를 시킬 수 있는 것이 장점이다. 아이들은 퍼핀 앱에서 대중교통 요금을 결제하고 편의점에서 간식을 사 먹는 식으로 용돈을 쓸 수 있다.`, quote:"매일경제", date:"2023.01.30", url:"https://www.mk.co.kr/news/economy/10623649" },
      { title:`KB국민카드, 레몬트리 등 ‘퓨처나인’ 6기 12개사 데모데이 개최`, content:`KB국민카드(사장 이창권)가 혁신 스타트업을 발굴·협업하는 ‘퓨처나인(FUTURE9)’ 6기 프로그램에 참여한 기업들과 함께 각 사의 비즈니스 모델과 KB국민카드와 추진하고 있는 협업 및 공동사업을 발표했다. KB국민카드는 지난 5일 퓨처나인 참가 기업의 실적 설명회(IR) 및 공동사업화 성과와 전략방향을 소개하는 데모데이를 개최했다고 6일 밝혔다. 이번 데모데이 행사에는 △레몬트리 △오아시스비즈니스 △웰로 △마이노멀컴퍼니 △애기야가자 △말랑하니 △워커스하이 △아이오로라 △그린재킷 △스타코프 △위허들링 △닥터노아 등 12개사가 참가했다.`, quote:"한국금융", date:"2022.12.06", url:"https://www.fntimes.com/html/view.php?ud=202212060949388674ee0209bd21_18" },
      { title:`경제교육, 중학생땐 이미 늦죠…'슬기로운 돈쓰기' 알아야 행복해`, content:`어린이 경제금융교육 팔걷은 초등학교 선생님 3인방 "집에 돈이 없다"는 말이 최악 돈만 아는 아이로 클 수밖에 스타트업 레몬트리 자문인연 연구회운영·책쓰고·유튜브도 동료와 함께 전방위 경제교육 학급 아이들에 기본임금 주고 경제공동체 속 살아있는 교육`, quote:"매일경제", date:"2022.08.17", url:"https://www.mk.co.kr/news/special-edition/10425311" },
      { title:`"아이들에게 용돈 굴리는 방법 가르쳐야죠"`, content:`“성인이 됐을 때 돈을 잘 굴리고 모으려면 일찍부터 좋은 금융 습관을 길러줄 수 있는 서비스가 필요하다고 생각해요.” 이민희 대표가 작년 7월 창업한 스타트업 ‘레몬트리’는 어린이와 청소년에게 초점을 맞춘 핀테크(fintech·첨단 기술을 접목한 금융) 회사다. 오는 9월 서비스 출시가 목표다.`, quote:"조선일보", date:"2022.05.31", url:"https://www.chosun.com/economy/economy_general/2022/05/31/JP3DZS2KK5ATPL26IS7CVT4Q5I/" },
      { title:"레몬트리, 어린이·청소년에게 금융지식 재밌게 알려줘", content:`레몬트리는 요즘 핀테크 업계에서 떠오르는 스타트업이다. 콘셉트는 '우리 아이 금융의 시작', 그간 금융 소외계층이나 다름없었던 어린이 경제교육과 주니어 금융시장을 노린다. 만 9세부터 18세를 주 고객으로 한 국내 최초 사업 모델인 데다  부모·자녀 고객을 잡으면 자연스럽게 '가족 금융'까지 확장할 수 있다는 점에서 금융권 관심이 쏠리고 있다.`, quote:"매일경제", date:"2022.02.21", url:"https://www.mk.co.kr/news/special-edition/view/2022/02/162890/" },
      { title:"'에듀테크보다 페어런트테크'...해외 VC 우르르 투자", content:"해외에서 ‘페어런트 테크(Parent Tech)’ 투자 열풍이 솔솔 분다. 페어런트 테크는 코로나19로 아동을 돌보는 시간이 길어진 부모들을 위한 기술 지원 필요성이 두드러지면서 생겨난 개념이다. 관련 솔루션으로는 어린이를 위한 승차 공유 서비스와 아동 금융 교육 플랫폼, 아동을 위한 비대면 행동건강 치료 플랫폼 등이 있다.", quote:"이데일리", date:"2021.12.08", url:"https://www.edaily.co.kr/news/read?newsId=01118486629276552&mediaCodeNo=257" },
      { title:"저금통 대신… 요즘 어린이들 앱으로 돈모아요", content:"자녀의 경제 교육의 필수품으로 적금 통장이 아니라 핀테크 앱이 떠오르고 있다. 자녀에게 용돈을 어떻게 모으고, 투자하는지 알려주면서 재테크를 조기 교육하려는 부모들이 늘어나면서 미성년자 대상 핀테크 시장이 급속히 커지고 있는 것이다. 아이들에게 금융교육을 제공하는 핀테크 스타트업 레몬트리는 지난 26일 초기 단계 투자에서 50억원 규모의 투자를 유치했다고 밝혔다.", quote:"조선일보", date:"2021.10.29", url:"https://www.chosun.com/economy/tech_it/2021/10/29/OUAJY5GIVVEDBDU4T5QVHR4ERI/" },
      { title:"키즈 핀테크 스타트업 ‘레몬트리’, 시드라운드에서만 50억원 투자 유치", content:"국내 최초 키즈 핀테크 스타트업 레몬트리가 창업과 동시에 시드라운드에서 50억원 규모의 투자유치를 했다. 이번 투자에는 KB인베스트먼트, 스프링캠프, 캡스톤파트너스, 티비티 파트너스, 베이스인베스트먼트, 패스트벤처스, 디캠프(은행권청년창업재단)등 유수의 투자기관들이 참여했다.", quote:"플래텀", date:"2021.10.26", url:"https://platum.kr/archives/173512" },
    ]
  }
}
