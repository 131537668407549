<template>
  <SectionBasic
    id="press"
    :isActive="isActive">
    <h3 class="tit_press_section">언론보도</h3>
    <swiper
      v-if="viewModel.isMobile"
      class="swiper_press"
      :options="swiperPressOption">
      <swiper-slide v-for="(articleData,index) in viewModel.model.pressDataList" :key="'pressSwiper'+index">
        <div
          class="item_press">
          <div class="inner_press">
            <strong class="tit_press">{{ articleData.title }}</strong>
            <p class="desc_quote">
              <span class="badge_quote">{{ articleData.quote }}</span>
              <span class="txt_date">{{ articleData.date }}</span>
            </p>
            <p class="desc_press">{{ articleData.content }}</p>
            <a :href="articleData.url" class="link_press" target="_blank">
              <IconSvg
                iconName="arrow_right"
                iconColor="#fff"
                :size="24"/>
            </a>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination swiper-press-pagination" slot="pagination"></div>
    </swiper>
    <template v-else>
      <ul :class="['list_press', {'list_press_all' : isPressMore}]">
        <li
          class="item_press"
          v-for="( articleData, index ) in viewModel.model.pressDataList"
          :key="`article${index}`">
          <div class="inner_press">
            <strong class="tit_press">{{ articleData.title }}</strong>
            <p class="desc_quote">
              <span class="badge_quote">{{ articleData.quote }}</span>
              <span class="txt_date">{{ articleData.date }}</span>
            </p>
            <p class="desc_press">{{ articleData.content }}</p>
            <a :href="articleData.url" class="link_press" target="_blank">
              <IconSvg
                iconName="arrow_right"
                iconColor="#fff"
                :size="24"/>
            </a>
          </div>
        </li>
      </ul>
      <button
        v-if="!isPressMore"
        class="btn_more_press"
        @click="onViewMorePress()">더보기</button>
    </template>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { Swiper, SwiperSlide, Navigation, Pagination } from 'vue-awesome-swiper'
import IconSvg from '@/components/common/icon/IconSvg.vue';

export default {
  name:'SectionPress',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    Swiper,
    SwiperSlide,
    Pagination,
    Navigation,
    IconSvg,
  },
  data(){
    return{
      isPressMore:false,
      swiperPressOption: {
        // autoplay: {
        //   delay: 3000
        // },
        loop: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 40,
        centeredSlides: false,
        speed:100,
        mousewheel:{
          forceToAxis:true,
        },
        pagination: {
          el: '.swiper-press-pagination',
          type: 'bullets',
          clickable: true
        },
      },
    }
  },
  methods:{
    onViewMorePress(){
      this.isPressMore = true;
    }
  }
}
</script>

<style scoped>
.section_press{padding:120px 0 100px;background-color:#BEA9ED;text-align:center}
.section_press .tit_press_section{font-weight:700;font-size:48px;line-height:64px;color:#333}
.section_press .list_press{overflow:hidden;max-height:972px;margin:60px -20px 0;text-align:left}
.item_press{display:inline-block;width:50%;padding:20px;vertical-align:top;box-sizing:border-box}
.item_press .inner_press{overflow:hidden;padding:32px 32px 24px;border-radius:8px;text-align:left;background-color:#fff;box-sizing:border-box;}
.item_press .link_press{display:block}
.item_press .tit_press{display:block;overflow:hidden;font-weight:700;font-size:28px;line-height:36px;color:#111;white-space:nowrap;text-overflow:ellipsis}
.item_press .desc_quote{margin-top:16px}
.item_press .desc_quote .badge_quote{display:inline-block;padding:4px 8px;border-radius:2px;font-weight:600;font-size:12px;line-height:16px;background-color:#F1EEE1;color:#111;vertical-align:top}
.item_press .desc_quote .txt_date{display:inline-block;margin-left:8px;font-weight:600;font-size:16px;line-height:24px;color:#666;vertical-align:top}
.item_press .desc_press{display:block;display:-webkit-box;overflow:hidden;margin-top:16px;font-weight:600;font-size:16px;line-height:24px;color:#999;-webkit-line-clamp:3;-webkit-box-orient:vertical}
.item_press .link_press{float:right;width:48px;height:48px;padding:12px;margin-top:16px;border-radius:100%;background-color:#232323;box-sizing:border-box}
.btn_more_press{display:block;width:180px;height:56px;margin:40px auto 0;border-radius:4px;font-weight:700;font-size:18px;line-height:28px;background-color:#232323;color:#fff}
.section_press .list_press.list_press_all{max-height:2000px}

/* 모바일 */
@media all and (max-width:1199px){
  .section_press{padding:60px 0}
  .section_press .tit_press_section{font-size:28px;line-height:42px;text-align:left}
  .section_press .swiper_press{margin:32px 0 0;padding-bottom:42px}
  .section_press .item_press .link_press{display:block}
  .section_press .item_press{display:block;width:100%;padding:0}
  .section_press .item_press .tit_press{display:-webkit-box;overflow:hidden;font-size:18px;line-height:28px;color:#111;white-space:inherit;text-overflow:inherit;-webkit-line-clamp:2;-webkit-box-orient:vertical}
  .section_press .item_press .desc_quote .txt_date{margin-left:8px;font-weight:500;font-size:14px}
  .section_press .item_press .desc_press{font-weight:500;font-size:14px;line-height:22px}
  .section_press .item_press .link_press{width:40px;height:40px;padding:8px;margin-top:22px}
  .section_press .swiper_press >>> .swiper-pagination-bullets{height:10px;bottom:0}
  .section_press .swiper_press >>> .swiper-pagination-bullet{width:10px;height:10px;margin:0 3px;opacity:1;background-color:#D8CBF4;vertical-align:top}
  .section_press .swiper_press >>> .swiper-pagination-bullet-active{background-color:#8F69DD}
}
</style>