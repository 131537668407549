<template>
  <SectionBasic
    id="team"
    :isActive="isActive">
    <h3 class="screen_out">팀 구성원</h3>
    <p class="tit_section">IT, 핀테크, 교육 분야에서 성공적인 경험을 쌓은<br>최고의 전문가들이 만들어갑니다.</p>
    <swiper
      v-if="viewModel.isMobile"
      class="swiper_team"
      :options="swiperTeamOption">
      <swiper-slide v-for="(memberData,index) in viewModel.model.teamDataList" :key="'teamSwiper'+index">
        <div
          class="item_member">
          <div class="inner_member">
            <div class="profile_member" :style="{ 'background-image':`url(/assets/images/profiles/${memberData.profileImg}.jpg)` }"></div>
            <strong class="txt_name" >{{ memberData.name }}</strong>
            <span class="txt_position">{{ memberData.position }}</span>
            <p
              class="desc_career"
              v-html="`${memberData.career}`"></p>
            <p
              v-if="memberData.message"
              class="desc_message"
              v-html="memberData.message"></p>
            <div
              class="group_sns"
              v-if="memberData.snsList && memberData.snsList.length > 0">
              <a
                v-for="sns in memberData.snsList"
                :key="sns.snsName"
                :href="sns.snsUrl"
                class="link_sns"
                target="_blank">
                <IconSvg
                  :iconName="sns.snsName"
                  iconColor="#BEA9ED"
                  bgcolor="#F3F1F1"
                  :size="24"/>
              </a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <ul v-else class="list_member">
      <li
        class="item_member"
        v-for="( memberData, index ) in viewModel.model.teamDataList"
        :key="`member${index}`">
        <div class="inner_member">
          <div class="profile_member" :style="{ 'background-image':`url(/assets/images/profiles/${memberData.profileImg}.jpg)` }"></div>
          <strong class="txt_name" >{{ memberData.name }}</strong>
          <span class="txt_position">{{ memberData.position }}</span>
          <p
            class="desc_career"
            v-html="`${memberData.career}`"></p>
          <p
            v-if="memberData.message"
            class="desc_message"
            v-html="memberData.message"></p>
          <div
            class="group_sns"
            v-if="memberData.snsList && memberData.snsList.length > 0">
            <a
              v-for="sns in memberData.snsList"
              :key="sns.snsName"
              :href="sns.snsUrl"
              class="link_sns"
              target="_blank">
              <IconSvg
                :iconName="sns.snsName"
                iconColor="#BEA9ED"
                bgcolor="#F3F1F1"
                :size="24"/>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';
import { Swiper, SwiperSlide, Navigation, Pagination } from 'vue-awesome-swiper'
import IconSvg from '@/components/common/icon/IconSvg.vue';

export default {
  name:'SectionTeam',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
    Swiper,
    SwiperSlide,
    Pagination,
    Navigation,
    IconSvg,
  },
  data(){
    return{
      swiperTeamOption: {
        // autoplay: {
        //   delay: 3000
        // },
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 16,
        freeMode: true,
        speed:100,
        preventClicks:false,
        mousewheel:{
          forceToAxis:true,
        },
        // pagination: {
        //   el: '.swiper-team-pagination',
        //   type: 'bullets',
        //   clickable: true
        // },
      },
    }
  }
}
</script>

<style scoped>
.section_team{padding:120px 0 100px;background-color:#F3F1F1;text-align:center}
.section_team .tit_section{font-size:48px;line-height:64px}
.list_member{display:inline-block;margin:60px -20px 0;text-align:left;vertical-align:top}
.item_member{display:inline-block;padding:20px;vertical-align:top}
.item_member .inner_member{width:290px;height:520px;padding:24px 0 53px;border-radius:8px;background-color:#fff;box-sizing:border-box;text-align:center;}
.item_member .profile_member{width:180px;height:180px;margin:0 auto;border-radius:100%;background-repeat:no-repeat;background-size:100%}
.item_member .txt_name{display:block;margin-top:16px;font-weight:800;font-size:24px;line-height:30px;color:#111}
.item_member .txt_position{display:block;margin-top:16px;font-weight:800;font-size:16px;line-height:24px;color:#8F69DD}
.item_member .desc_career{margin-top:16px;font-weight:500;font-size:14px;line-height:22px;color:#999}
.item_member .desc_message{margin-top:16px;padding:0 16px;font-weight:600;font-size:14px;line-height:22px;word-wrap:break-word;word-break:keep-all;color:#666}
.item_member .group_sns{margin-top:16px;line-height:24px}
.item_member .group_sns .link_sns{display:inline-block;height:24px;vertical-align:top}
.item_member .group_sns .link_sns + .link_sns{margin-left:20px}
.item_member .group_sns .link_sns .icon_firfin{border-radius:100%}
/* 모바일 */
@media all and (max-width:1319px){
  .list_member{text-align:center}
}
/* 모바일 */
@media all and (max-width:1199px){
  .section_team{padding:60px 0;text-align:left}
  .section_team .tit_section{font-size:30px;line-height:45px}
  .section_team .swiper_team .swiper-slide{width:264px;height:auto}
  .section_team .swiper_team{margin:32px 0 0;text-align:center}
  .section_team .item_member{display:inline-block;width:264px;height:100%;padding:0;vertical-align:top}
  .section_team .item_member .inner_member{width:100%;height:100%}
  .section_team .item_member .profile_member{width:120px;height:120px}
  .section_team .item_member .desc_career{padding:0 20px;word-wrap:break-word;word-break:keep-all}
  /* .section_team .swiper_team >>> .swiper-pagination-bullets{height:10px;bottom:0}
  .section_team .swiper_team >>> .swiper-pagination-bullet{width:10px;height:10px;margin:0 3px;opacity:1;background-color:#D8CBF4;vertical-align:top}
  .section_team .swiper_team >>> .swiper-pagination-bullet-active{background-color:#8F69DD} */
}
</style>