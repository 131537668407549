<template>
  <PageWithLayout>
    <h2 class="screen_out">회사소개</h2>
    <SectionMission
      :isActive="sectionsTop.mission.show" />
    <SectionIntro
      :isActive="sectionsTop.intro.show"
      :viewModel="viewModel" />
    <SectionAbout
      :isActive="sectionsTop.about.show" />
    <!-- <SectionTeam
      :isActive="sectionsTop.team.show"
      :viewModel="viewModel" /> -->
    <SectionPress
      :isActive="sectionsTop.press.show"
      :viewModel="viewModel" />
    <SectionSupport
      :isActive="sectionsTop.support.show" />
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// components
import SectionMission from '@/views/company/view/components/mission/SectionMission';
import SectionIntro from '@/views/company/view/components/intro/SectionIntro';
import SectionAbout from '@/views/company/view/components/about/SectionAbout';
import SectionTeam from '@/views/company/view/components/team/SectionTeam';
import SectionPress from '@/views/company/view/components/press/SectionPress';
import SectionSupport from '@/views/company/view/components/support/SectionSupport';

// viewModel
import CompanyViewModel from '@/views/company/viewModel/CompanyViewModel';
// mixins
import showSection from '@/mixins/ShowSection'

export default {
  name:'Company',
  mixins:[showSection],
  components:{
    PageWithLayout,

    SectionMission,
    SectionIntro,
    SectionAbout,
    SectionTeam,
    SectionPress,
    SectionSupport
  },
  data(){
    return{
      viewModel: new CompanyViewModel(),
      sectionsTop:{
        mission: { id:'mission',top:0,show:true },
        intro: { id:'intro',top:null,show:true },
        about: { id:'about',top:null,show:false },
        team: { id:'team',top:null,show:false },
        press: { id:'press',top:null,show:false },
        support: { id:'support',top:null,show:false },
      }
    }
  },
  metaInfo: {
    meta: [
      {
        property: "description",
        content: "용돈 교육을 시작으로, 자녀의 경제적 독립을 앞당기고 경제적 자유를 누리는 가족이 될 수 있도록, 레몬트리와 퍼핀이 가족 금융 라이프스타일을 이끌어 나가겠습니다.",
        vmid:"description",
      },
      {
        property: "og:description",
        content: "용돈 교육을 시작으로, 자녀의 경제적 독립을 앞당기고 경제적 자유를 누리는 가족이 될 수 있도록, 레몬트리와 퍼핀이 가족 금융 라이프스타일을 이끌어 나가겠습니다.",
        vmid:"og:description",
      },
      {
        property: "twitter:description",
        content: "용돈 교육을 시작으로, 자녀의 경제적 독립을 앞당기고 경제적 자유를 누리는 가족이 될 수 있도록, 레몬트리와 퍼핀이 가족 금융 라이프스타일을 이끌어 나가겠습니다.",
        vmid:"twitter:description",
      },
    ],
  },
}
</script>
<style scoped>
/* common */
.section_comm >>> .txt_section{display:block;font-family:'Poppins';font-weight:700;font-size:32px;line-height:28px;color:#D8CBF4}
.section_comm >>> .tit_section{font-weight:700;font-size:54px;line-height:65px;color:#333}
.section_comm >>> .txt_section + .tit_section{margin-top:24px}

/* 모바일 */
@media all and (max-width:1199px){
  /* common */
  .section_comm >>> .txt_section{font-size:18px;line-height:28px}
  .section_comm >>> .tit_section{font-size:30px;line-height:45px}
  .section_comm >>> .txt_section + .tit_section{margin-top:10px}
}
</style>