<template>
  <SectionBasic
    id="mission"
    :isActive="isActive">
    <h3 class="txt_section">Our mission</h3>
    <p class="desc_mission">레몬트리는 자녀의 미래에<br>부가 따를 수 있게 한다는 <span class="txt_break_response">미션을 수행합니다.</span></p>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionMission',
  props:{
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_mission{padding:160px 0 120px;background-color:#fff;text-align:center}
.section_mission .desc_mission{margin-top:24px;font-weight:700;font-size:54px;line-height:65px;color:#333}

/* 모바일 */
@media all and (max-width:1199px){
  .section_mission{padding:114px 0 60px;text-align:left}
  .section_mission .desc_mission{margin-top:32px;font-size:30px;line-height:45px}
}
</style>