<template>
  <SectionBasic
    id="intro"
    :isActive="isActive">
    <h3 class="screen_out">레몬트리 스토리</h3>
    <div class="group_half">
      <div class="half_right">
        <div class="area_img area_img1">
          <div class="inner_img"></div>
        </div>
      </div>
      <div class="half_left area_middle">
        <div class="inner_middle">
          <h4 class="tit_intro">미국 가정 경제 교육의 시작,<br>레모네이드 스탠드</h4>
          <p class="desc_section">미국에는 아이들이 직접 레모네이드를 만들어 팔아 용돈을 벌고, 자연스럽게 돈과 경제개념을 익히는 가정 교육 문화가 있습니다.</p>
        </div>
      </div>
    </div>
    <div class="group_half">
      <div class="half_left">
        <div class="area_img area_img2">
          <div class="inner_img"></div>
        </div>
      </div>
      <div class="half_right area_middle">
        <div class="inner_middle">
          <h4 class="tit_intro">우리나라는 어떤가요?</h4>
          <p class="desc_section">학교에서도 가정에서도, 실질적인 금융경제 교육을 접하기 어렵습니다.<br> 그렇게 배우고 자라 부모가 되고 나니, 아이에게 어떻게 가르쳐야할지 막막합니다.</p>
          <p class="desc_section desc_point">"우리 집은 부자야?라고 묻는 아이에게 뭐라고 답해야하나요?"<br> "내 아이만큼은 돈 걱정 없이 살면 좋겠어요."</p>
          <p class="desc_section">용돈 교육을 시작으로, 자녀의 경제적 독립을 앞당기고 경제적 자유를 누리는 가족이 될 수 있도록,<br> 레몬트리가 가족 금융 라이프스타일을 이끌어 나가겠습니다.</p>
        </div>
      </div>
    </div>
  </SectionBasic>
</template>

<script>
import SectionBasic from '@/components/common/section/SectionBasic.vue';

export default {
  name:'SectionIntro',
  props:{
    viewModel: Object,
    isActive: Boolean,
  },
  components:{
    SectionBasic,
  },
}
</script>

<style scoped>
.section_intro{padding:120px 0 100px;background-color:#F1EEE1}
.section_intro .tit_intro{font-weight:700;font-size:48px;line-height:64px;color:#111}
.section_intro .desc_section{margin-top:24px;font-weight:500;font-size:20px;line-height:30px;color:#666;word-wrap:break-word;word-break:keep-all}
.section_intro .desc_section.desc_point{color:#111}
.section_intro .area_img1 .inner_img{height:100%;background:url(/assets/images/company/img_intro1.png)no-repeat 0 0;background-size:100%}
.section_intro .area_img2 .inner_img{height:100%;background:url(/assets/images/company/img_intro2.png)no-repeat 0 0;background-size:100%}
.section_intro .group_half + .group_half{margin-top:60px}
.section_intro .half_right{padding:0 0 20px 20px}
.section_intro .half_left{padding:0 20px 20px 0}
.section_intro .half_right.area_middle{padding-left:60px}

/* 모바일 */
@media all and (max-width:1199px){
  .section_intro{padding:60px 0}
  .section_intro .tit_intro{font-size:28px;line-height:42px}
  .section_intro .desc_section{margin-top:32px;font-weight:700;font-size:18px;line-height:27px}
  .section_intro .area_img1,
  .section_intro .area_img2{position:static;max-width:620px}
  .section_intro .area_img1 .inner_img,
  .section_intro .area_img2 .inner_img{padding-top:100%}
  .section_intro .group_half + .group_half{margin-top:32px}
  .section_intro .half_right,
  .section_intro .half_left{padding:0}
  .section_intro .half_left.area_middle,
  .section_intro .half_right.area_middle{max-height:inherit;padding:0;margin-top:32px}
  .section_intro .half_right.area_middle .desc_section br{display:none}
  .section_intro .half_right.area_middle .desc_point br{display:inherit}
}
</style>